import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "“Tribes” By Seth Godin Review",
  "author": "tzookb",
  "type": "post",
  "date": "2019-01-06T14:55:28.000Z",
  "url": "/2019/01/tribes-by-seth-godin-review/",
  "categories": ["Book Reviews"],
  "tags": ["books", "business", "marketing"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` Nice book that helps you think on us as humans and they way we like forming into small tribes. While reading it you can recognize small tribes in your life that simply formed around someone or around some idea. Nice read, just open you up for thinking in `}{`“`}{`a movement`}{`”`}{` way instead of just products/features/ideas. A lot of great stories and overall nice read. `}<strong parentName="p">{`Book in a Glance`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`a tribe is a group of people connected to a leader and an idea`}</li>
      <li parentName="ul">{`a group needs two things to become a tribe: interest and way to communicate`}</li>
      <li parentName="ul">{`Tribes needs leadership. It could be one leader or more. People want to be a part of something`}</li>
    </ul>
    <p><strong parentName="p">{`5 key elements for a micro movement`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`publish a manifesto`}
        <ul parentName="li">
          <li parentName="ul">{`share it, and make sure people will know about it`}</li>
        </ul>
      </li>
      <li parentName="ul">{`make it easy for followers to connect with you`}</li>
      <li parentName="ul">{`make it easy for followers to connect with one another`}</li>
      <li parentName="ul">{`money is not the point of the movement`}
        <ul parentName="li">
          <li parentName="ul">{`you or your followers may make money from the movement but thats not the main reason for its existence`}</li>
        </ul>
      </li>
      <li parentName="ul">{`track your progress`}
        <ul parentName="li">
          <li parentName="ul">{`share progress path for you and for your followers`}</li>
        </ul>
      </li>
    </ul>
    <p><strong parentName="p">{`Principles`}{` `}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Transparency`}{` `}</li>
      <li parentName="ul">{`the movement is bigger then the leader`}</li>
      <li parentName="ul">{`never stop growing`}</li>
      <li parentName="ul">{`make it clear by comparing to the status quo or movements in the other direction`}</li>
      <li parentName="ul">{`exclude outsiders`}</li>
      <li parentName="ul">{`don’t ruin others, build your own`}</li>
    </ul>
    <p><strong parentName="p">{`Leader Responsibility`}{` `}</strong>{` If someone: `}</p>
    <ul>
      <li parentName="ul">{`don’t believe the idea`}</li>
      <li parentName="ul">{`don’t buy the product`}</li>
      <li parentName="ul">{`gets bored from your presentation`}</li>
      <li parentName="ul">{`etc Its the leaders fault not the could be follower `}</li>
    </ul>
    <p><em parentName="p">{`Change isn’t made by asking permission . Change is made by asking forgiveness , later.`}</em>{` `}<em parentName="p">{`Leaders who set out to give are more productive than leaders who seek to get .`}</em>{` `}<em parentName="p">{`The secret of leadership is simple : Do what you believe in . Paint a picture of the future . Go there . People will follow.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      